<template>
  <div class="cover-container">
    <img :src="getURL()" alt="">
    <div class="cover-button-block" :class="{'cover-button-block-anima-show':isShowButton}">
      <div></div>
      <div><i></i><span></span></div>
    </div>

  </div>
</template>
<script>
export default {
  name:"cover",
  props:{
    isShowButton:{
      type:Boolean,
      default:false
    },
    url:{
      type:String,
      default:""
    }
  },
  data(){
    return {
      cover:""
    }
  },
  methods:{
    setURL(url){
      this.cover = url
    },
    getURL(){
      return this.cover ||　this.url ||　require('@/assets/images/404/404-3-2.jpg')
    }
  }
}
</script>
<style>
.cover-container{
  width:100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  z-index:9999999;
}
.cover-container img{
  width:100%;
  height:100%;
}


.cover-button-block {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11000;
  /* display: flex;
        align-items: center;
        justify-content: center;; */
}
.cover-button-block > div {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cover-button-block:first-child {
  position: absolute;
}
.cover-button-block > div:first-child {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.cover-button-block-anima-show  > div:first-child{
  animation: playButton 0.5s ease-in-out infinite alternate;
  -webkit-animation: playButton 0.5s ease-in-out infinite alternate;
}
@keyframes playButton {
  0% {
    transform: scale(1, 1);
    box-shadow: 0 0px 1px rgba(129, 48, 75, 1);
  }
  100% {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0px 20px rgba(129, 48, 75, 1);
  }
}
@-webkit-keyframes playButton {
  0% {
    transform: scale(1, 1);
    box-shadow: 0 0px 1px rgba(129, 48, 75, 1);
  }
  100% {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0px 20px rgba(129, 48, 75, 1);
  }
}
.cover-button-block:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover-button-block:last-child > div {
  width: 15.33vw;
  height: 15.33vw;
  border-radius: 50%;
  /* border: .4vw solid #fff; */
}
.cover-button-block:last-child > div > i {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* background: #999; */
  border:1px solid #999;
  border-radius: 50%;
  position: relative;
  z-index: -1;
  opacity: 0.9;
}
.cover-button-block:last-child > div > span {
  display: inline-block;
  width: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: rgb(210, 210, 210);
  position: absolute;
  height: 0;
  border-style: solid;
  border-left-width: 5vw;
  top: 4.83vw;
  left: 6.33vw;
  border-right-width: 5vw;
  border-top-width: 3.33vw;
  border-bottom-width: 3.33vw;
}

</style>