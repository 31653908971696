<template>
    <div class="loading_container global_full_screen global_container_center">
        <el-icon class="loading_button" >
          <Loading />
        </el-icon>
    </div>
</template>
<script>
import {ElIcon} from 'element-plus';
import { Loading } from "@element-plus/icons"
export default {
    name:"loading",
    components:{
      Loading,
      ElIcon
    }
}
</script>
<style scoped>
.loading_container{
  position: fixed;
  left:0;
  top:0;
  background:rgba(30,30,30,0.5);

}
.loading_button{
  /* font-size:4vw; */
  color:#fff;
  /* display: inline-block; */
  /* animation: round1 1s infinite; */
}
@keyframes round1 {
  0%{-webkit-transform:rotate(0deg);}
  25%{-webkit-transform:rotate(90deg);}
  50%{-webkit-transform:rotate(180deg);}
  75%{-webkit-transform:rotate(270deg);}
  100%{-webkit-transform:rotate(360deg);}
}
</style>
