<template>
    <div class="coursewares_container" @click="showSelect(false)">
        <div class="coursewares_search">
          <div class="coursewares_search_title">全部课程</div>
          <div class="coursewares_search_block">
              <div class="coursewares_search_label_name" @click.stop="showSelect">
                筛选
                <el-icon>
                  <Filter />
                </el-icon>
              </div>
              <div class="coursewares_search_label_ul" v-if="isShowSelect">
                <div class="coursewares_search_label_li" @click="selectCancers(item.value)" v-for="(item) in config.cancers" :key="item.label">{{ item.label }}</div>
              </div>
          </div>
        </div>
        <div class="coursewares_label" >
          <div class="coursewares_label_name global_primary_background_color">{{ search.cancer ||"全部" }}</div>
        </div>
        <ListScroll :url="$urls.admin.courseware" :select="search" :config='{isCache:true,prefix:"coursewares"}' ref="ListScroll">
            <template   v-slot:listItem="{citems}" >
               <coursewaresItem :data="citems" @doSelect="doSelect" :isSelect="allUse.includes(citems.id)"  @click="goToDetail(citems)"></coursewaresItem>
            </template>
        </ListScroll>
    </div>
</template>

<script>
import ListScroll from "@/components/unit/ListScroll"
import { Filter } from "@element-plus/icons"
import coursewaresItem from "@/components/page/admin/coursewaresItem"
export default {
    name: 'coursewares',
    data(){
        return{
          config:{
            cancers:["肺啊啊癌","肝啊癌","jj癌"]
          },
          search:{
            cancer:""
          },
          allUse:[],
          isShowSelect:false,
          isLoading:false
        }
    },

    mounted(){
        this.loadCancers()
        this.loadClassUse()
    },
    components:{
        ListScroll,
        Filter,
        coursewaresItem,
    },
    // $route.query.type 0 管理员  1 医生
    methods:{
      loadCancers(){
        this.$axios.get(this.$urls.admin.cancers).then(res=>{
          this.config.cancers = res.data.map(item=>{
            return {
              label:item,
              value:item
            }
          })
          this.config.cancers.unshift({
            label:"全部",
            value:""
          })
        })
      },
      loadClassUse(){
        this.$axios.get(this.$urls.admin.haveCourseware).then(res=>{
          this.allUse = res.data.map(x=>x.id)
        })
      },
      doSearch(){
        this.$refs.ListScroll.searchData(this.search);
      },
      showSelect(status){
        if( typeof status == "boolean"){
          this.isShowSelect = status;
          return;
        }
        this.isShowSelect = !this.isShowSelect
      },
      doSelect(data){
        if(this.allUse.includes(data.id) || this.isLoading) return
        this.allUse.push(data.id)
        if(this.$route.query.type == 0){
          let meetingInfo = this.$tools.getLocalCookie("meetingInfo")
          meetingInfo.courseware_id = data.id
          this.$tools.setLocalCookie("meetingInfo",meetingInfo)
          this.$router.back()
        }else{
          this.doCompleteCourseware(data.id)
        }
      },
      doCompleteCourseware(id){
        this.isLoading = true
        this.$axios.post(this.$urls.admin.bindCourseware,{id:this.$route.query.meetId,courware_id:id}).then(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
          this.$router.back()
        }).catch(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
        })
      },
      selectCancers(cancer){
        this.search.cancer = cancer
        this.showSelect()
        this.doSearch()
      },
      
      goToDetail(citem){
        // id 课件id, type 0 管理员  1专家， select:是否需要选择，cancer进去后查询的cancer
        this.$router.push({
            path:"/preview",
            query:{
                coursewareId:citem.id,
                ...this.$route.query,
                select:1,
                ...this.search
            }
        })
      }
    }
}
</script>

<style>
    .coursewares_container{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        padding:4vw;
    }
    .coursewares_search{
        padding: 2vw 0;
        display: flex;
        justify-content: space-between;
    }
    .coursewares_search_title{
      font-weight: 700;
    }
    .coursewares_search_block{
      position: relative;
    }
    .coursewares_label_name{
      display: inline-block;
      padding:1vw 3vw;
      color:#fff;
      border-radius:1vw;
    }
    .coursewares_search_label_name{
      height:100%;
      font-weight:700;
      display:flex;
      align-items:center;
    }
    .coursewares_search_label_ul{
      position: absolute;
      top:6vw;
      right:0;
      z-index:10;
      background:#fff;
      white-space: nowrap;
      padding:0 0 2vw;
      border:1px solid #000;
      border-radius:1vw;
      
    }
    .coursewares_search_label_li{
      margin-top:2vw;
      padding:2vw;
      border-bottom:1px solid #000;
    }
    .coursewares_search_label_li:last-child{
      border:none;
    }

</style>